import React, { memo, useMemo } from 'react';
import { ISplitFactoryChildProps, SplitFactoryProvider } from '@splitsoftware/splitio-react';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { SPLIT_AUTH_KEY, SPLIT_KEY } from 'common/utils/env-variables';

export const SplitProvider = memo(({ children }) => {
  const { account } = useFormState();

  const splitAttributes = useMemo(() => {
    const attributes: {
      user_id?: number;
      location_id?: number;
      location_name?: string;
      location_timezone_name?: string;
    } = {};

    if (account?.id) {
      attributes.user_id = account.id;
      attributes.location_id = account.locationId;
    }

    return attributes;
  }, [account?.id, account?.locationId]);

  const config: SplitIO.IBrowserSettings = useMemo(
    () => ({
      core: {
        authorizationKey: SPLIT_AUTH_KEY,
        key: SPLIT_KEY,
      },
    }),
    [],
  );

  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout={true} attributes={splitAttributes}>
      {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => {
        if (!isReady) return <></>;

        return children;
      }}
    </SplitFactoryProvider>
  );
});
