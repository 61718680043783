import { BasicResponseStructure, CategoryIdEnum } from 'common/api/types';

export enum DiscountTypeEnum {
  Percent = 2,
  DollarAmount = 3,
  PricingOverride = 4, // this case, ignore amount value
}

export declare namespace GetPartnerInfoAction {
  export type Payload = {
    subpartner_token: string;
  };

  export type Discount = {
    amount: number;
    category: {
      id: CategoryIdEnum;
      name: string;
    };
    details: string;
    type: {
      id: DiscountTypeEnum;
      name: string;
    };
  };

  export type Partner = {
    id: number;
    name: string;
    partner: {
      id: number;
      name: string;
    };
    type: {
      id: number;
      name: string;
    };
    logo: {
      url: string;
    };
    booking: {
      title: string;
      image_url: string;
    };
    notes: {
      type_of_junk: string;
      location_of_junk: string;
      special_instructions: string;
      moving_factors: string;
    };
    discounts: Discount[];
    source_id?: number;
  };

  export type Response = {
    subpartners: Partner[];
  } & BasicResponseStructure;
}
