/**
 * Getting env variable
 * @throwable
 */
const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is not defined`);
  }
  return process.env[key] || '';
};

/** PARTNER_NAME ENV **/
export const PARTNER_NAME = getEnvVar('REACT_APP_PARTNER_NAME');

/** API entrypoint */
export const API_URL = getEnvVar('REACT_APP_API_URL');

/** Old App link */
export const hunkwareAppURL = getEnvVar('REACT_APP_DOMAIN_URL');

/** BugSnag Application ID **/
export const BUGSNAG_API_KEY = getEnvVar('REACT_APP_BUGSNAG_API_KEY');

/** Google Application ID **/
export const GOOGLE_API_KEY = getEnvVar('REACT_APP_GOOGLE_API_KEY');

/** Square Application ID **/
export const SQUARE_APP_ID = getEnvVar('REACT_APP_SQUARE_APP_ID');

/** Split.io Auth Key **/
export const SPLIT_AUTH_KEY = getEnvVar('REACT_APP_SPLIT_AUTH_KEY');

/** Split.io Key **/
export const SPLIT_KEY = getEnvVar('REACT_APP_SPLIT_KEY');

/** Google analytics ID **/
export const GTM_ID = getEnvVar('REACT_APP_GTM_ID');

/** Google analytics tracking ID **/
export const GA_TRACKING_ID = getEnvVar('REACT_APP_GA_TRACKING_ID');
